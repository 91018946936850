.board {
  position: relative;
  margin: 50px auto 0 auto;
  width: 750px;
  height: 750px;
  border: 2px solid #000000;
}

.worm-body {
  position: absolute;
  width: 5%;
  height: 5%;
  border: 1px solid #3d1f00;
}

.worm-head {
  border-radius: 40%;
}

.heart {
  background-color: #FF0000;
  display: inline-block;
  height: 18px;
  position: absolute;
  top: 0;
  transform: rotate(-45deg);
  width: 18px;
}
.heart:before {
  top: -9px;
  left: 0;
}
.heart:after {
  left: 9px;
  top: 0;
}
.heart:before, .heart:after {
  content: "";
  background-color: #FF0000;
  border-radius: 50%;
  height: 18px;
  position: absolute;
  width: 18px;
}

.pad-left {
  padding-left: 3px;
}/*# sourceMappingURL=Board.css.map */