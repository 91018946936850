.crypto-header{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:85%;
    margin: 20px auto;
}
.nav-item{
    display:flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding-bottom:3px;
    & span{
      padding-left: 6px;
    }
}
.no_line{
    text-decoration: none;
}
.coin-hover {
    text-decoration: none;
    border-bottom: 3px solid #efefef;
    transition: all 0.25s linear;
    position: relative;
    &:before{
      content: "";
      left: 0;
      bottom: -3px;
      width: 100%;
      height: 3px;
      background-color: #000000;
      position: absolute;
      transform-origin: center;
      transform: scale(0);
      transition: 0.25s linear;
    }
    &:hover:before{
      transform:scale(1);
    }
  }

  .navbar-links{
    display:flex;
    & a{
      margin-left:25px;
    }
  }
