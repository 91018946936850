$primary-blue: #79c5d6;
$primary-red: #ff3b3f;
$bg-grey:#efefef;
$text-grey:#696666;
$selection-color:#9cedff77;
* {
  margin: 0;
  padding: 0;
  color: $text-grey;
  &:before, &:after{
    box-sizing: inherit;
  }
  &::selection{
    background:$selection-color;
  }
}
html {
  box-sizing: border-box;
}
body {
  background: $bg-grey;
  letter-spacing: 0.05em;
}
.wrapper {
  width: 80%;
  margin: auto;
}
.mobile-vanish {
  display: none;
}
.project-container,
.experience-container {
  font-family: "Raleway", "sans-serif";
  padding-block: 1.5rem;
}
.project-header,
.experience-header {
  padding-top: 15px;
}
.project-title {
  font-size: 0.95rem;
  text-align: center;
}
.project-image {
  border-radius: 5%;
}
.hover-link {
  text-decoration: none;
  border-bottom: 3px solid $primary-red;
  transition: all 0.25s linear;
  position: relative;
  &:before{
    content: "";
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 3px;
    background-color: $primary-blue;
    position: absolute;
    transform-origin: center;
    transform: scale(0);
    transition: 0.25s linear;
  }
  &:hover{
    &:before{
      transform: scale(1);
    }
  }
}
.hover-cover {
  position: relative;
  text-decoration: none;
  display: inline-block;
  color: black;
  padding: 2px 5px;
  transition: color ease 0.3s;
  &:after{
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: $primary-red;
    transition: all ease 0.15s;
  }
  &:hover{
    color: $bg-grey;
    &:after{
      background-color: $primary-blue;
      height: 100%;
    }
  }
}

@media (min-width: 768px) {
  .wrapper {
    width: 85%;
  }
  .mobile-vanish {
    display: initial;
  }
}

@media (min-width: 1024px) {
  .wrapper {
    width: 85%;
  }
  .header {
    padding-block: 5%;
  }
}

@media (min-width: 1200px) {


  .image-socials {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
  header {
    padding-block: 5%;
    align-items: unset;
  }
  .myInfo {
    text-align: unset;
    & > h1{
      letter-spacing: 0.35rem;
    }
  }
  #displayedImage {
    padding: 0 5%;
  }
  .socials {
    margin: unset;
    justify-content: unset;
  }
  .socialLink {
    margin-right: 10%;
  }
  .aboutMe p {
    margin-bottom: 3%;
  }
  .row {
    display: flex;
    flex-direction: row;
    & > .project-image{
      padding-left: 4%;
    }
  }
  .row-reverse {
    display: flex;
    flex-direction: row-reverse;
    & > .project-image{
      padding-right: 4%;
    }
  }
  .project-image {
    max-width: 40% !important;
  }
  .projects {
    padding-bottom: 4rem;
  }
}
@media (min-width: 1400px){
.wrapper{
  width:70%;
}
}