.project-image {
  max-width: 100%;
}
.project-title {
  font-size: 1.4rem;
  padding-block: 1.2rem;
}
.project-heading {
  padding-bottom: 0.5rem;
  font-style: italic;
  text-align:center;
  font-size: 2.5em;
}
.project-heading-description {
  text-align:center;
  padding-bottom:1rem;
}

.project-description {
  font-size: 1.1rem;
  letter-spacing: 0.025em;
  margin-bottom: 1.875rem;
  line-height: 1.35em;
}
