$heart-red:#FF0000;
$worm-border: #3d1f00;
$board-border: #000000;
$board-size: 750px;
.board{
    position:relative;
    margin:50px auto 0 auto;
    width:$board-size;
    height:$board-size;
    border:2px solid $board-border;
}
.worm-body{
    position:absolute;
    width:5%;
    height:5%;
    border:1px solid $worm-border;
}
.worm-head{
  border-radius: 40%;
}
.heart {
    background-color: $heart-red;
    display: inline-block;
    height: 18px;
    position: absolute;
    top: 0;
    transform: rotate(-45deg);
    width: 18px;
    &:before{
      top: -9px;
      left: 0;
    }
    &:after{
      left: 9px;
      top: 0;
    }
    &:before,&:after{
      content: "";
      background-color: $heart-red;
      border-radius: 50%;
      height: 18px;
      position: absolute;
      width: 18px;
    }
  }
  .pad-left{
    padding-left:3px;
  }
