.socialIcon {
  width: 20px;
  fill: #ff3b3f;
}
.socialIcon:hover {
  fill: #79c5d6;
  transform: scale(1.3);
  transition: 150ms;
}

.socials {
  display: flex;
  margin: 5%;
  align-items: center;
  justify-content: space-evenly;
}/*# sourceMappingURL=socials.css.map */